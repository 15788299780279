import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility.js';
import commonHelper from '@/app/utility/common.helper.utility';
import submittedRequest from '../../request/submittedRequest/submittedRequest';
import RunRequest from '../../request/submittedRequest/runRequest';
export default {
  name: 'crystalReceipt',
  components: {
    DatePicker,
    submittedRequest,
    RunRequest
  },
  watch: {
    currentPage: function() {
      this.getReceiptByCrystalApi();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getReceiptByCrystalApi();
    }
  },
  data() {
    return {
      loader: false,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      siteId: {
        text: null,
        value: null
      },
      status: {
        text: null,
        value: null
      },
      meterNumber: null,
      crystal_receipt_from_to: [],
      crystalReceiptData: [],
      crystalReceiptFields: [
        {
          key: 'crystal_site_name'
        },

        {
          key: 'meter_no'
        },
        {
          key: 'from_date'
        },
        {
          key: 'to_date'
        },
        {
          key: 'status_meaning'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_update_date'
        },
        {
          key: 'last_updated_by'
        }
      ],
      vsetCode: null,
      showValueSetModal: false,
      requestStatus: null,
      requestStatus1: null,
      variantType: null,
      showRunRequestModal: false,
      reqCrystalReciept: {
        fromDate: null,
        toDate: null,
        crsystalSite: {
          text: null,
          value: null
        }
      },
      templateId:null,
      requestId:null
    };
  },
  methods: {
    getReceiptByCrystalApi() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        site_id: this.siteId.value,
        staus: this.status.value,
        meter_no: this.meterNumber,
        from_date: commonHelper.formattedDate(this.crystal_receipt_from_to[0]),
        to_date: commonHelper.formattedDate(this.crystal_receipt_from_to[1])
      };
      this.loader = true;
      this.$store
        .dispatch('receivables/getReceiptByCrystalApi', payload)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.crystalReceiptData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.STATUS_FLAG) {
        this.status.text = item.value_meaning;
        this.status.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CRYSTAL_SITE_VSET) {
        this.siteId.text = item.crystal_site_name;
        this.siteId.value = item.crystal_site_id;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues() {
      if (this.vsetCode === appStrings.VALUESETTYPE.CRYSTAL_SITE_VSET) {
        this.siteId.text = null;
        this.siteId.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.STATUS_FLAG) {
        this.status.text = null;
        this.status.value = null;
      }
    },
    clearSearch() {
      (this.siteId = {
        text: null,
        value: null
      }),
        (this.status = {
          text: null,
          value: null
        }),
        (this.meterNumber = null);
      this.crystal_receipt_from_to = [];
      this.getReceiptByCrystalApi();
    },
    runRequest() {
      this.reqCrystalReciept = {
        fromDate: this.crystal_receipt_from_to[0],
        toDate: this.crystal_receipt_from_to[1],
        crsystalSite: {
          text: this.siteId.text,
          value: this.siteId.value
        }
      };
      this.showRunRequestModal = true;
    },
    validGenBatchData(type) {
      this.variantType = type;
      const payload = {
        template_name:
          type === 'process'
            ? 'Process_crys_receipt_temp'
            : 'Create_crys_receipt_temp'
      };
      this.$store
        .dispatch('template/getTemplateList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.templateId = response.data.data.page[0].template_id;
            this.requestId = response.data.data.page[0].request_id;
            if (this.templateId && this.requestId) {
              this.saveSubmitRequest();
            } else {
              this.$bvToast.toast('Something Went wrong. Please try again', {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveSubmitRequest() {
      const payload = {
        admin_submit_req_details: [
          {
            request_num: 0,
            request_id: this.requestId,
            template_id: this.templateId,
            schedule_type: 'asap',
            request_start_date: null,
            request_end_date: null,
            resubmit_interval: null,
            resubmit_interval_unit: null,
            day_of_month: null,
            day_of_week: null,
            output_format_id: 'BLANK',
            no_of_args: 1,
            request_parameter: null,
            sms_flag: false,
            email_flag: false,
            whatsapp_flag: false,
            child_req_count: null,
            email_comm_template_id: null,
            sms_comm_template_id: null,
            whatsapp_comm_template_id: null,
            argument1: null,
            argument2: null,
            argument3: null,
            argument4: null,
            argument5: null,
            argument6: null,
            argument7: null,
            argument8: null,
            argument9: null,
            argument10: null,
            argument11: null,
            argument12: null,
            argument13: null,
            argument14: null,
            argument15: null,
            argument16: null,
            argument17: null,
            argument18: null,
            argument19: null,
            argument20: null,
            argument21: null,
            argument22: null,
            argument23: null,
            argument24: null,
            argument25: null
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.requestNumId = resp.data.data[0].id;
            this.getSubmitRequest(this.variantType);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSubmitRequest(type) {
      if (type === 'process') {
        this.loader = true;
        this.$store
          .dispatch('template/getSubmitRequest', this.requestNumId)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.requestStatus = resp.data.data[0].status;
              this.requestNumId = resp.data.data[0].id;
              if (this.requestStatus === 'COMPLETED')
                this.getReceiptByCrystalApi();
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else if (type === 'create') {
        this.loader = true;
        this.$store
          .dispatch('template/getSubmitRequest', this.requestNumId)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.requestStatus1 = resp.data.data[0].status;
              this.requestNumId = resp.data.data[0].id;
              if (this.requestStatus1 === 'COMPLETED')
                this.getReceiptByCrystalApi();
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    resetModal(){
      this.showRunRequestModal = false;
    }
  }
};
